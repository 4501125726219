import React, { useState } from "react";

export default function ItemTabla({ data }) {

    const [game, setGame] = useState(data);
    console.log(data.descripcion);
    return (
        <tr>
            <td>{game.gameId}</td>
            <td><img src={game.pictures[0]} alt={game.name} /></td>
            <td id="tablaJuegosTitulo">
                <div id="tablaJuegosTituloInfo">
                    <h3>{game.name}</h3>
                    <p>{game.description.slice(0, 100)}</p>
                </div>
            </td>
            <td>{game.editor}</td>
            <td>{game.year}</td>
            <td>{game.agemin}-{game.agemax}</td>
            <td>{game.playersmin}-{game.playersmax}</td>
            <td>{game.duration}</td>
            <td>{game.category}</td>
            <td>
            {game.tags.map((tag, index) => {
                return <p key={index}>{tag}</p>
            })}

            </td>


        </tr>

    );
}
