import React from "react";
import logo from '../assets/logobajada.png';

export function Main() {
    return(
    <main>
        <img src={logo} alt="logo" />
        <h1 id="subtitle">Una nueva manera de encontrarse</h1>

    </main>
    )
}
