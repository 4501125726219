import React from 'react';
import { useEffect, useState } from 'react';
import './App.css';
import { Switch } from 'react-router';
import {BrowserRouter, Route} from 'react-router-dom'

import {Cargar} from './components/Cargar';
import {Main} from './components/Main';
import { Catalogo } from './components/Catalogo';


function App() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch('/api/date');
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/cargar" component={Cargar} />
          <Route exact path="/catalogo" component={Catalogo} />
          <Route exact path="/" component={Main} />
        </Switch>

    </BrowserRouter>
  );
}

export default App;
