import React from "react";
import ItemTabla from "./ItemTabla";

export default function TablaJuegos({ data }) {

    return (
        <React.Fragment>
            <table id='tablaJuegos'>
                <thead>
                    <tr>
                        <th>ID Juego</th>
                        <th></th>
                        <th>Titulo</th>
                        <th>Editorial</th>
                        <th>Año</th>
                        <th>Edad</th>
                        <th>Jugadores</th>
                        <th>Duracion</th>
                        <th>Categoria</th>
                        <th>Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(juego => {
                        return <ItemTabla data={juego} />
                    })}
                </tbody>
            </table>

        </React.Fragment>
    )


}
