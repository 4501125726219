import React, { useState } from 'react'

export function Cargar() {

    const [juegoGuardado, setJuegoGuardado] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const [gameID, setGameID] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [barcode, setBarcode] = useState('')
    const [editor, setEditor] = useState('')
    const [year, setyear] = useState(0)
    const [ageMin, setAgeMin] = useState(0)
    const [ageMax, setAgeMax] = useState(99)
    const [playersMin, setPlayersMin] = useState(1)
    const [playersMax, setPlayersMax] = useState(99)
    const [duration, setDuration] = useState(0)
    const [category, setCategory] = useState('')
    const [picture1, setPicture1] = useState('https://picsum.photos/100')
    const [picture2, setPicture2] = useState('')
    const [picture3, setPicture3] = useState('')
    const [picture4, setPicture4] = useState('')
    const [tag1, setTag1] = useState('')
    const [tag2, setTag2] = useState('')
    const [tag3, setTag3] = useState('')
    const [tag4, setTag4] = useState('')



    function updateName(event) {
        setName(event.target.value)
    }
    function updateGameID(event) {
        setGameID(event.target.value)
    }
    function updateDescription(event) {
        setDescription(event.target.value)
    }
    function updateBarcode(event) {
        setBarcode(event.target.value)
    }
    function updateEditor(event) {
        setEditor(event.target.value)
    }
    function updateYear(event) {
        setyear(event.target.value)
    }
    function updateAgeMin(event) {
        setAgeMin(event.target.value)
    }
    function updateAgeMax(event) {
        setAgeMax(event.target.value)
    }
    function updatePlayersMin(event) {
        setPlayersMin(event.target.value)
    }
    function updatePlayersMax(event) {
        setPlayersMax(event.target.value)
    }
    function updateDuration(event) {
        setDuration(event.target.value)
    }
    function updateCategory(event) {
        setCategory(event.target.value)
    }
    function updatePicture1(event) {
        setPicture1(event.target.value)
    }
    function updatePicture2(event) {
        setPicture2(event.target.value)
    }
    function updatePicture3(event) {
        setPicture3(event.target.value)
    }
    function updatePicture4(event) {
        setPicture4(event.target.value)
    }
    function updateTag1(event) {
        setTag1(event.target.value)
    }
    function updateTag2(event) {
        setTag2(event.target.value)
    }
    function updateTag3(event) {
        setTag3(event.target.value)
    }
    function updateTag4(event) {
        setTag4(event.target.value)
    }

    function clearForm() {
        setName('')
        setGameID('')
        setDescription('')
        setBarcode('')
        setEditor('')
        setyear('')
        setAgeMin(0)
        setAgeMax(99)
        setPlayersMin(1)
        setPlayersMax(99)
        setDuration(0)
        setCategory('')
        setPicture1('https://picsum.photos/100')
        setPicture2('')
        setPicture3('')
        setPicture4('')
        setTag1('')
        setTag2('')
        setTag3('')
        setTag4('')

    }

    function cargarOtroJuego() {
        console.log('limpiando');
        clearForm()
        setJuegoGuardado(false)
        setHuboError(false)
    }

    function saveGame() {
        let joinTags = [tag1, tag2, tag3, tag4]
        let joinPictures = [picture1, picture2, picture3, picture4]
        console.log(gameID, name, description, barcode, editor, year, ageMin, ageMax, playersMin, playersMax, duration, category, joinTags,joinPictures);

        fetch('https://api.ensamblados.com.ar/v1/games', {
        //fetch('http://localhost:9000/v1/games', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                gameId: gameID,
                name: name,
                description: description,
                barcode: barcode,
                editor: editor,
                year: year,
                agemin: ageMin,
                agemax: ageMax,
                playersmin: playersMin,
                playersmax: playersMax,
                duration: duration,
                category: category,
                tags: joinTags,
                pictures: joinPictures
            })
        })
            .then(response => {
                console.log(response.status);
                if (response.status === 200) {
                    setJuegoGuardado(true)
                } else {
                    setHuboError(true)
                }
                clearForm()
                return response.json()
            })
            .then(data => {
                console.log(data)
            }
            )


    }

    return (
        <main>
            {!juegoGuardado && !huboError
                ?
                <div className="FormularioCargaContainer">
                    <form className="FormularioCarga">
                        <div className="form-group">
                            <label htmlFor="gameIDInput">Id del Juego:</label>
                            <input type="text" className="form-control" id="gameIDInput" aria-describedby="nameHelp" placeholder="Ingresa el ID del juego" onChange={updateGameID.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="emailInput">Nombre:</label>
                            <input type="text" className="form-control" id="nameInput" aria-describedby="emailHelp" placeholder="Ingresa su nombre" onChange={updateName.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="descriptionInput">Descripcion:</label>
                            <textarea name="Text1" cols="27" rows="5" placeholder="Ingresa una descripcion del juego" onChange={updateDescription.bind(this)}></textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="barcodeInput">Codigo Barras:</label>
                            <input type="text" className="form-control" id="barcodeInput" placeholder="Ingresa el codigo de barra" onChange={updateBarcode.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="editorInput">Editorial:</label>
                            <input type="text" className="form-control" id="editorInput" placeholder="Ingresa la editorial" onChange={updateEditor.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="yearInput">Año:</label>
                            <input type="text" className="form-control" id="yearInput" placeholder="año" onChange={updateYear.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="minAgeInput">Edad minima:</label>
                            <input type="text" className="form-control" id="minAgeInput" placeholder="edad" onChange={updateAgeMin.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="maxAgeInput">Edad maxima:</label>
                            <input type="text" className="form-control" id="maxAgeInput" placeholder="edad" onChange={updateAgeMax.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="minPlayersInput">Jugadores desde:</label>
                            <input type="text" className="form-control" id="minPlayersInput" placeholder="cantidad" onChange={updatePlayersMin.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="maxPlayersInput">Jugadores hasta:</label>
                            <input type="text" className="form-control" id="maxPlayersInput" placeholder="cantidad" onChange={updatePlayersMax.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="durationInput">Duracion (min):</label>
                            <input type="text" className="form-control" id="durationInput" placeholder="minutos" onChange={updateDuration.bind(this)}></input>
                        </div>
                        <div className="form-group">
                            <label htmlFor="categoryInput">Categoria:</label>
                            <input type="text" className="form-control" id="categoryInput" placeholder="categoria" onChange={updateCategory.bind(this)}></input>
                        </div>
                        <div className="form-group-especial">
                            <label htmlFor="tagsInput">Tags:</label>
                            <div>
                                <input type="text" className="form-control" id="tagsInput" placeholder="Tag" onChange={updateTag1.bind(this)}></input>
                                <input type="text" className="form-control" id="tagsInput" placeholder="Tag" onChange={updateTag2.bind(this)}></input>
                                <input type="text" className="form-control" id="tagsInput" placeholder="Tag" onChange={updateTag3.bind(this)}></input>
                                <input type="text" className="form-control" id="tagsInput" placeholder="Tag" onChange={updateTag4.bind(this)}></input>
                            </div>
                        </div>
                        <div className="form-group-especial">
                            <label htmlFor="pictureInput">Imagen (url):</label>
                            <div>
                                <input type="text" className="form-control" id="pictureInput" placeholder="Imagen" onChange={updatePicture1.bind(this)}></input>
                                <input type="text" className="form-control" id="pictureInput" placeholder="Imagen" onChange={updatePicture2.bind(this)}></input>
                                <input type="text" className="form-control" id="pictureInput" placeholder="Imagen" onChange={updatePicture3.bind(this)}></input>
                                <input type="text" className="form-control" id="pictureInput" placeholder="Imagen" onChange={updatePicture4.bind(this)}></input>
                            </div>
                        </div>

                        <button onClick={e => { e.preventDefault(); saveGame() }} className="btn btn-primary">Cargar Juego</button>
                    </form>
                </div>
                :
                <React.Fragment>
                    {juegoGuardado ?

                        <div>
                            <div>Juego guardado correctamente</div>
                            <button onClick={() => cargarOtroJuego()}>cargar otro</button>
                        </div>
                        :
                        <div>
                            <div>Hubo un error al cargar el juego</div>
                            <button onClick={() => cargarOtroJuego()}>Reintentar</button>
                        </div>
                    }

                </React.Fragment>
            }
        </main>

    )
}


