import React, { useEffect, useState } from 'react'
import TablaJuegos from './Catalogo/TablaJuegos';

export function Catalogo() {

    const [games, setGames] = useState({})


    useEffect(() => {

        fetch('https://api.ensamblados.com.ar/v1/games', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                
                return response.json();            
            })
            .then(response => {
                console.log(response);
                setGames(response)
            })
    },[])

    return (
        <main>
            {games && games.length > 0
                ?
                <TablaJuegos data={games}/>
                :
                <div>No hay juegos en la base de datos</div>
            }
        </main>

    )
}